import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeProvider";

export default function Header() {
  const navigate = useNavigate();
  const [currentDomain, setCurrentDomain] = useState('');

  const { theme, setTheme } = useContext(ThemeContext);

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/invalid");
  };

  useEffect(() => {
    setCurrentDomain(window.location.hostname);
  }, []);

  return (
    <div className="header">
      <div>

        <img
          className="header-logo"
          src="/images/getorade.jpeg"
          alt=""
          style={{ width: "70px" }}
        />


        <img
          className="header-logo"
          width={180}
          src={
            currentDomain === 'mastercardjnk.vouch.club'
              ? theme
                ? "/images/J&KDarkLogo.png"
                : "/images/J&KLightLogo.png"
              : currentDomain === 'hccb.vouch.club'
                ? "/images/hccb_logo.png"
                : theme
                  ? "/images/almond-logo-dark.png"
                  : "/images/almond-logo.png"
          }
          alt=""
        />

      </div>
      <div className="mc-header-buttons-container">
        <div
          className="mc-theme-toggle-button-container"
          onClick={() => {
            setTheme(!theme);
          }}
        >
          {theme ? (
            <i class="fa-solid fa-sun"></i>
          ) : (
            <i class="fa-solid fa-moon"></i>
          )}
        </div>
        <button className="btn btn-logout" onClick={handleLogout}>
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
          <span className="mc-logout-button-text">Logout</span>
        </button>
      </div>
    </div>
  );
}
