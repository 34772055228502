import axios from "axios";

export const loginWithMobile = async (mobile) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASEURL}/api/user/login`, {
            mobile,
            project_name: "Getorade Gift Voucher"
        });
        return response;
    } catch (err) {
        return err.message;
    }
}