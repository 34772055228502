import React, { useContext } from 'react'
import { ThemeContext } from "../context/ThemeProvider";
import Footer from '../layout/Footer'

const Invalid = () => {

    return (
        <>
            <div className="container">
                <div className='mc-image-holder'>


                    <img
                        style={{ margin: "0 auto 40px" }}
                        className="login-logo"
                        src={"/images/getorade.jpeg"}
                        alt=""
                    />

                </div>
                <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    <h2>Thanks for Visiting Us!</h2>
                    <h3>Have a Great Time!</h3>
                </div>
            </div>
            <div
                className="footer-banner"
                style={{ bottom: "0px", position: "fixed", width: "100%" }}
            >
                <Footer />
            </div>
        </>
    )
}

export default Invalid